import { LocaleResource } from '.'

const frCA: LocaleResource = {
  'activityPanel.filter.allActivity': 'Toutes les activités',
  'activityPanel.filter.audits': 'Audits',
  'activityPanel.filter.exceptions': 'Exceptions',
  'activityPanel.filter.issues': 'Problèmes',
  'activityPanel.filter.shifts': 'Quarts de travail',
  'activityPanel.filter.tasks': 'Tâches',
  'activityPanel.rowContent.exception.closed': 'Fermé',
  'activityPanel.rowContent.exception.closedDuration':
    'Fermé: {{time}} ({{duration}})',
  'activityPanel.rowContent.exception.duration': 'Durée',
  'activityPanel.rowContent.exception.open': 'Exception Ouvert',
  'activityPanel.rowContent.exception.opened': 'Ouvert',

  'activityPanel.rowContent.shiftEnd': 'Quart de travail terminé',
  'alert.message.exportConfirmationConfirm':
    'Voulez-vous vraiment exporter les enregistrements {{totalCount}} sélectionnés?',
  'alert.message.exportConfirmationError':
    "Un problème est survenu lors de l'exportation de vos données. Veuillez réessayer ou, si le problème persiste, contactez l'assistance",
  'alert.message.exportConfirmationSuccess':
    "Exportation de données créée avec succès. L'exportation sera bientôt disponible à partir de l'onglet <underline>Rapports > Exportations</underline>.",
  'alert.message.exportConfirmationWarning':
    "Taille d'exportation trop grande. Veuillez filtrer les résultats sur moins de 500 000 enregistrements.",
  'alert.message.exportConfirmationWarningPdf':
    "Taille d'exportation trop grande. Filtrez les résultats sur moins de 1 000 enregistrements.",
  'alert.message.pageNotFound':
    'La page que vous recherchez est introuvable. Veuillez contacter le support.',
  'alert.message.profileSuccessfullyUpdated':
    'Votre profil a été mis à jour avec succès',
  'alert.message.requiredDataMissing': 'Données obligatoires manquantes',
  'alert.message.selectBeforeAddingSignal':
    "Sélectionnez un emplacement, un bâtiment ou une clôture géographique avant d'ajouter un signal",
  'alert.message.signalMustBeInSelectedArea':
    'Le signal doit être créé dans la zone sélectionnée',
  'alert.message.unableToCreateSignal':
    'Impossible de créer un signal. Veuillez contacter le support.',
  'alert.message.unableToExportPDF':
    "Impossible d'exporter le PDF. Veuillez réessayer ou contacter l'assistance.",
  'alert.networkError':
    "Il y a eu un problème avec la demande en raison d'une erreur réseau",
  'alert.noItemsCouldBeFound': "Aucun {{item}} n'a pu être trouvé",
  'alert.noSchedulesFound': "Aucun horaire n'a été trouvé",
  'alert.noZonesToShow': 'Aucune zone disponible à afficher',
  'alert.pleaseSelectALocation': 'Veuillez sélectionner un emplacement',
  'alert.pleaseSelectFilter':
    'Veuillez sélectionner au moins un filtre de localisation',
  'alert.title.addSignal': 'Ajouter un signal',
  'alert.title.error': 'Erreur',
  'alert.message.locationAddTaskTemplateLocked':
    "Le modèle de cette tâche est verrouillé et ne peut pas être ajouté à cet emplacement. Pour attribuer cet emplacement à ce formulaire, modifiez-le depuis l'écran des modèles.",
  'alert.message.locationAddIssueTemplateLocked':
    'La plantilla de esta tarea está bloqueada y no se puede agregar a esta ubicación. Para asignar esta ubicación a este formulario, edítelo desde la pantalla de Plantillas',
  'alert.message.locationAddAuditTemplateLocked':
    "Le modèle de cet audit est verrouillé et ne peut pas être ajouté à cet emplacement. Pour attribuer cet emplacement à ce formulaire, modifiez-le depuis l'écran des modèles.",
  'alert.message.reinviteUserSentTo':
    "L'invitation a été renvoyée avec succès à",
  'alert.message.reinviteUserSentToError': "Échec du renvoi de l'invitation à",
  'alert.message.reinviteUserSent': "L'invitation a été renvoyée avec succès",
  'alert.message.reinviteUserSentError': "Échec du renvoi de l'invitation",

  'reinviteUser.statusBanner.message': `Ce compte a été invité <1>{{difference}}</1>, veuillez vérifier que l'adresse e-mail est correcte ci-dessous.`,
  'reinviteUser.statusBanner.saveToResend': `Enregistrer les modifications pour renvoyer l'invitation.`,

  'areas.jobNumberTooltip.message':
    "Pour les clients de WinTeam, vous pouvez faire référence au numéro d'emploi correspondant",
  'areas.jobNumberTooltip.title': "Numéro d'emploi WinTeam",

  'audit.targetTooltip.message':
    'Ajoutez le nombre souhaité comme norme de performance dans le champ Cible. La saisie de "85" dans le champ Cible définirait la norme de mesure à 85 %.',
  'audit.targetTooltip.title': 'Entrez la Cible',

  'auditPanel.header.audit': 'Audit',
  'auditPanel.header.newAudit': 'Nouvel audit',

  backToSignIn: "Retour à la connexion à l'application",

  'button.add': 'Ajouter',
  'button.addAnother': 'Ajouter un autre',
  'button.addBuilding': 'Ajouter un bâtiment',
  'button.addField': 'Ajouter un champ',
  'button.addGeofence': 'Ajouter un périmètre virtuel',
  'button.addGroup': 'Ajouter un groupe',
  'button.addOption': 'Ajouter une option',
  'button.addQuestion': 'Ajouter une question',
  'button.addQuote': 'Ajouter un devis',
  'button.addServiceHours': 'Ajouter des heures de service',
  'button.addUser': 'Ajouter un utilisateur',
  'button.addUsers': 'Ajouter des utilisateurs',
  'button.addingUsers': 'Ajouter des utilisateurs',
  'button.back': 'Dos',
  'button.cancel': 'Annuler',
  'button.clear': 'Effacer',
  'button.clearFilters': 'Effacer les filtres',
  'button.completeSignup': 'Inscription complète',
  'button.confirm': 'Confirmer',
  'button.continue': 'Continuer',
  'button.delete': 'Supprimer',
  'button.deleteConfirm': 'Souhaitez-vous vraiment supprimer {{name}}?',
  'button.deleteConfirm_schedule':
    "Souhaitez-vous vraiment supprimer cet horaire? Supprimer l'horaire éliminera tous les exemples actifs et futurs de cet horaire.",
  'button.deleteConfirm_small': 'Confirmer la suppression?',
  'button.deleteConfirm_unnamed': 'Souhaitez-vous vraiment supprimer?',
  'button.deviceRel': 'Rel appareil',
  'button.discard': 'Éliminer',
  'button.discardConfirm': 'Souhaitez-vous vraiment éliminer vos changements?',
  'button.discardConfirm_small': 'Éliminer les changements?',
  'button.duplicate': 'Double',
  'button.edit': 'Modifier',
  'button.editBoundaries': 'Modifier les limites',
  'button.exit': 'Quitter',
  'button.fetchingApplication': "Récupération de l'application",
  'button.filter': 'Filtre',
  'button.forgotPassword': 'Mot de passe oublié',
  'button.goLive': 'Aller en direct',
  'button.hide': 'Cacher',
  'button.invite': 'Inviter',
  'button.inviteUser': "Inviter l'utilisateur",
  'button.invitingUser': "Invitation de l'utilisateur en cours",
  'button.legend': 'Légende',
  'button.live': 'En direct',
  'button.map': 'CARTE',
  'button.moveDown': 'Déplacer vers le bas',
  'button.moveUp': 'Déplacer vers le haut',
  'button.newApplication': 'Nouvelle application',
  'button.newEntry': 'Nouvelle inscription',
  'button.newGroup': 'Nouveau groupe',
  'button.newLoop': 'Nouvelle boucle',
  'button.newRole': 'Nouveau rôle',
  'button.newSchedule': 'Nouvel horaire',
  'button.newSignal': 'Nouveau signal',
  'button.newTemplate': 'Nouveau modèle',
  'button.next': 'Suivant',
  'button.now': 'Maintenant',
  'button.pdf': 'PDF',
  'button.printCodes': "Codes d'impression",
  'button.printLabels': 'Imprimer les étiquettes',
  'button.processing': 'Traitement',
  'button.qrCodes': 'Codes QR',
  'button.remove': 'Retirer',
  'button.removeFormGroupConfirm': 'Supprimer le groupe de formulaires?',
  'button.resendInvite': `Renvoyer l'invitation`,
  'button.reset': 'Réinitialiser',
  'button.save': 'Sauvegarder',
  'button.saveConfirm': 'Souhaitez-vous vraiment sauvegarder vos changements?',
  'button.saveConfirm_schedule':
    "Souhaitez-vous vraiment mettre à jour cet horaire? La mise à jour de l'horaire remplacera tous les exemples actifs et futurs de cet horaire.",
  'button.saveConfirm_small': 'Sauvegarder les changements?',
  'button.saveGroup': 'Sauvegarder le groupe',
  'button.saving': 'Sauvegarde en cours',
  'button.signIn': 'Se connecter',
  'button.syncing': 'Synchronisation',
  'button.today': "Aujourd'hui",
  'button.update': 'Mettre à jour',

  'error.applicationNotFound': "L'application n'a pas pu être trouvée",
  'error.duplicateDocument':
    'Les données que vous avez envoyées duplique un document qui existe déjà',

  'inputSelect.selectAll': 'Sélectionner tous les {{totalCount}} {{type}}s',
  'inputSelect.showCountt': 'Affichage de {{totalCount}} {{type}}s',
  'inputSelect.removeSelected': 'Supprimer {{selectedCount}} {{type}}s',

  'issuePanel.header.issue': 'Problème',
  'issuePanel.header.newIssue': 'Nouveau problème',

  label12HourClock: 'Horloge de 12 heures',
  label24HourClock: 'Horloge de 24 heures',
  labelAccessPermissions: "Permissions d'accès",
  labelActivity: 'Activité',
  labelActivityFeed: "Flux d'activités",
  labelAddress: 'Adresse',
  labelAllDay: 'Toute la journée',
  labelAllLocations: 'Tous les emplacements',
  labelAllLocationGroups: `Tous les groupes d'emplacements`,
  labelAllTitle: 'Tous {{title}}',
  labelAlwaysBackground: 'Toujours (arrière-plan)',
  labelAnswer: 'Répondre',
  labelAppDebugging: 'Débogage des applications',
  labelArea: 'Région',
  labelAreas: 'Régions',
  labelAreasAndSignals: 'Zones et Signaux',
  labelAssignTo: 'Assigné à',
  labelAssignedTo: 'Assigné à',
  labelAssignee: 'Cessionnaire',
  labelAssignees: 'Cessionnaires',
  labelAttachments: 'Pièces jointes',
  labelAudit: 'Audit',
  labelAuditGroups: "Groupes d'audit",
  labelAuditScoresAverageByLocation: "Notes d'audit (moyenne) par lieu",
  labelAuditScoresAverageOverTime: "Notes d'audit (moyenne) dans le temps",
  labelAuditTemplates: "Modèles d'audit",
  labelAudits: 'Audits',
  labelAuthentication: 'Authentification',
  labelAutomatically: 'Automatiquement',
  labelAverageExceptionDuration: "Durée moyenne de l'exception",
  labelBackInTime: 'Retour dans le temps',
  labelBeacon: 'Beacon',
  labelBeaconMajor: 'Beacon Majeur',
  labelBeaconMinor: 'Beacon Mineur',
  labelBeaconUuid: 'Beacon UUID',
  labelCheckboxes: 'Cases à cocher',
  labelCity: 'Ville',
  labelCloseTime: 'Heure de fermeture',
  labelClosed: 'Fermé',
  labelComments: 'commentaires',
  labelConfirmNewPassword: 'Confirmer le nouveau mot de passe',
  labelConfirmLocation: "Oui - Confirmer l'emplacement",
  labelConfirmGeofence: 'Oui - Confirmer la clôture géographique',
  labelConfirmLocationAndGeofence:
    'Oui - Confirmer la localisation et la clôture géographique',
  labelEndShiftConfirmGeofence:
    "Doit se trouver dans l'emplacement de début d'équipe, mais autoriser le contournement",
  labelEndShiftCopyStartShiftLocation:
    "Copier l'emplacement du début de l'équipe",
  labelEndShiftNone:
    "Aucune restriction - copier l'emplacement du début de l'équipe s'il est inconnu",
  labelContent: 'Contenu',
  labelContentEntries: 'Saisies de contenu',
  labelContentTemplate: 'Modèle de contenu',
  labelContentTemplates: 'Modèles de contenu',
  labelCountry: 'Pays',
  labelCreate: 'Créer',
  labelCreateNewGroup: 'Créer un nouveau groupe',
  labelCreated: 'Créé',
  labelDayforce: 'Dayforce',
  labelDayforceEmployeeBadgeNumber: "Numéro de badge d'employé",
  labelDailyLocationReport: 'Rapport quotidien de localisation',
  labelDailyLocationReports: 'Rapports quotidiens de localisation',
  labelDailyShiftReport: 'Rapport quotidien de quart de travail',
  labelDashboard: 'Tableau de bord',
  labelDate: 'Date',
  labelDayforceLocationXRefCode: "Code d'emplacement Dayforce",
  labelDayDate: 'Jour/date',
  labelDays: 'Journées',
  labelDefaultRole: 'Rôle par défaut',
  labelDefaultRoleDescription:
    'Ceci est le rôle par défaut pour les nouveaux utilisateurs.',
  labelDelete: 'Supprimer',
  labelDescription: 'Description',
  labelDetails: 'Informations',
  labelDisplayAlways: 'Toujours afficher',
  labelDisplayImage: "Afficher l'image",
  labelDisplayOnFormEntryOnly: 'Afficher sur entrée de formulaire seulement',
  labelDisplayText: 'Afficher le texte',
  labelDuration: 'Durée',
  labelDuressAlerts: 'Alertes de contrainte',
  labelEdit: 'Modifier',
  labelEditGroup: 'Groupe modificateur',
  labelEditGroupDetails: 'Informations sur le groupe modificateur',
  labelEmail: 'Courriel',
  labelEmailOrUsername: "Courriel ou nom d'utilisateur",
  labelEmployee: 'Employé',
  labelEmployeeNumber: "Numéro d'employé",
  labelEnabled: 'Activé',
  labelEnd: 'Fin',
  labelEndDate: 'Date de fin',
  labelEndLocation: 'Emplacement de fin',
  labelEndSchedule: 'Horaire de fin',
  labelEndShift: 'Quart de travail de fin',
  labelEndShiftRestriction: 'Restrictions de fin de quart',
  labelEndTime: 'Heure de fin',
  labelEnglishUs: 'Anglais (États-Unis)',
  labelEnglishUsShort: 'Anglais (États-Unis)',
  labelEnter: 'Entrer',
  labelEvent: 'Événement',
  labelEventType: "Type d'événement",
  labelEvents: 'Événements',
  labelExceptionClosed: 'Exception fermée',
  labelExceptionOpened: 'Exception ouverte',
  labelExceptions: 'Exceptions',
  labelExit: 'Sortir',
  labelExport: 'Exporter',
  labelExports: 'Exportations',
  labelField: 'Champ',
  labelFile: 'Fichier',
  labelFirstName: 'Prénom',
  labelFloors: 'Plancher(s)',
  labelFloorsTitle: 'Planchers',
  labelFooterFields: 'Champ de pied de page',
  labelFormTemplates: 'Modèles de formulaires',
  labelFrenchCa: 'Français (canadien)',
  labelFrenchCaShort: 'Français (CA)',
  labelFrom: 'De',
  labelGPS: 'GPS',
  labelGroupLabel: 'Étiquette de groupe',
  labelGroupName: 'Nom du groupe',
  labelGroupScore: 'Score du groupe',
  labelHeaderFields: "Champs d'en-tête",
  labelHighest: 'Plus élevé',
  labelHours: 'Les heures',
  labelID: 'ID',
  labelInactiveRole: 'Rôle inactif',
  labelInactiveRoleDescription:
    "Il s'agit du rôle destiné aux utilisateurs inactifs.",
  labelInProgress: 'En cours',
  labelIncludeUnassignedLocationData:
    'Inclure les données de localisation non assignées',
  labelInvited: 'Invité',

  labelIssue: 'Délivrer',
  labelIssueTemplates: 'Modèles de problèmes',
  labelIssues: 'Problèmes',
  labelIssuesByLocation: 'Problèmes par emplacement',
  labelIssuesOverTime: 'Problèmes au fil du temps',
  labelJobNumber: 'Numéro de poste',
  labelLastActivity: 'Dernière Activité',
  labelLastLogin: 'Dernière connexion,',
  labelLastName: 'Nom de famille',
  labelLastUpdated: 'Dernière mise à jour',
  labelLatitude: 'Latitude',
  labelLongitude: 'Longitude',
  labelLocation: 'Emplacement',
  labelLocationCount: "Nombre d'emplacements",
  labelLocationGroup: "Groupe d'emplacements",
  labelLocationGroups: "Groupes d'emplacements",
  labelLocationSettings: "Paramètres de l'emplacement",
  labelLocationSettingsDescription:
    "Contrôlez quand enregistrer la localisation d'un utilisateur avec les paramètres suivants",
  labelLocations: 'Emplacements',
  labelLocationsReports: "Rapports d'emplacements",
  labelLocked: 'Fermé à clé',
  labelLoopExceptions: 'Exceptions pour les boucles',
  labelLoopTimes: 'Heures des boucles',
  labelLoops: 'Boucles',
  labelLowest: 'Plus bas',
  labelManually: 'Manuellement',
  labelMaps: 'Carte',
  labelMedia: 'Média',
  labelMessageGroups: 'Groupes de messages',
  labelMessages: 'Messages',
  labelMinutes: 'Minutes',
  labelMobile: 'Mobile',
  labelMobileAndWeb: 'Mobile et web',
  labelMobileOnly: 'Mobile seulement',
  labelMonths: 'Mois',
  labelMultiLine: 'Multiligne?',
  labelMultiSelectList: 'Liste de sélection multiple',
  labelName: 'Nom',
  labelNewAndUpdatedIssuesAssignedToMe:
    'Problèmes nouveaux et mis à jour, assignés à moi',
  labelNewArea: 'Nouvelle zone',
  labelNewAudit: 'Nouvel audit',
  labelNewCustomEntry: 'Nouvelle saisie de {{template}}',
  labelNewGroup: 'Nouveau groupe',
  labelNewIssue: 'Nouveau problème, non attribués à moi',
  labelNewLoopTimes: 'Nouvelles heures des boucles',
  labelNewPassword: 'Nouveau mot de passe',
  labelNewRole: 'Nouveau rôle',
  labelNewTask: 'Nouvelle tâche',
  labelNewTemplate: 'Nouveau modèle',
  labelNewTemplateEntry: 'Nouvelle saisie dans le modèle',
  labelNewUser: 'Nouvel utilisateur',
  labelNfc: 'NFC',
  labelNo: 'Non',
  labelNoEndDate: 'Pas de date de fin',
  labelNoMessagesYet: 'Pas encore de message…',
  labelNoRepeat: 'pas de répétition',
  labelNotRepeatable: 'Non répétable',
  labelNotification: 'Notification',
  labelNotificationPreferences: 'Préférences de notifications',
  labelNumber: 'Nombre',
  labelOff: 'Désactivé',
  labelOn: 'Au',
  labelOnline: 'En ligne',
  labelOffline: 'Hors ligne',
  labelOpen: 'Ouvert',
  labelOpenTime: 'Temps ouvert',
  labelOpened: 'Ouvert',
  labelOptional: 'optionnel',
  labelOptions: 'Options',
  labelOverallScore: 'Score global:',
  labelParticipants: 'Participants',
  labelPassword: 'Password',
  labelPendingUser: 'Pending User',
  labelPlatformAccess: 'Accès à la plate-forme',
  labelPostCode: 'Code postal',
  labelProfile: 'Profil',
  labelPush: 'Pousser',
  labelQrCode: 'Code QR',
  labelQrCodes: 'Codes QR',
  labelQuestion: 'Question',
  labelQuestions: 'Questions',
  labelRadioButtons: 'Boutons radio',
  labelRaisedBy: 'Soulevé par',
  labelRecentIssues: 'numéros récents',
  labelRecentTasks: 'tâches récentes',
  labelReference: 'Référence',
  labelRelationship: 'Relation',
  labelRepeat: 'Répéter',
  labelRepeatFrequency: 'Répéter la fréquence',
  labelRepeatOn: 'Répéter le',
  labelRepeatOnSetTimeIntervals: 'Répétez sur des intervalles de temps fixés',
  labelRepeatOnTimeSinceLastActivityCompletion:
    'Répéter à temps depuis la fin de la dernière activité',
  labelRepeatable: 'Répétable',
  labelReportsDashboard: 'Tableau de bord des rapports',
  labelRequired: 'Requis?',
  labelRequiredField: 'requis',
  labelRestrictIssueClosing: 'Restreindre la clôture des problèmes',
  labelRole: 'Rôle',
  labelRoles: 'Rôles',
  labelRolesAndPermissions: 'Rôles et permissions',
  labelSMS: 'SMS',
  labelSchedule: 'Horaire',
  labelScheduleWork: "Travail à l'horaire",
  labelScheduledWork: "Travail à l'horaire",
  labelScore: 'Note',
  labelSection: 'Section',
  labelSelect: 'Sélectionner',
  labelSendAfter: 'Envoyer après',
  labelServiceHours: 'Heures de service',
  labelServiceLevel: 'Niveau de service',
  labelSetup: 'Configuration',
  labelShare: 'Partager le formulaire PDF',
  labelShiftFinished: 'Quart de travail terminé',
  labelShiftStarted: 'Quart de travail commencé',
  labelShiftStatus: 'État',
  labelShifts: 'Quarts de travail',
  labelSignal: 'Signal',
  labelSignals: 'Signaux',
  labelSignature: 'Signature',
  labelSingleSelectList: 'Sélectionner la liste',
  labelSkipLocationsWithNoActivity:
    "Sauter les emplacements n'ayant pas d'activité",
  labelSpanishLa: 'Espagnol (Amérique latine)',
  labelSpanishLaShort: 'Espagnol (LA)',
  labelSpecificActions: 'Actions précises',
  labelStarRating: 'Classement par étoiles',
  labelStart: 'Commencer',
  labelStartEndTime: 'Heure de début et de fin',
  labelStartLocation: 'Lieu de départ',
  labelStartShiftRestriction: 'Restrictions de début de quart de travail',
  labelStartSchedule: "Commencer l'horaire",
  labelStartShift: 'Commencer le quart de travail',
  labelStartTime: 'Heure de début',
  labelState: 'État',
  labelStatus: 'État',
  labelStreet: 'Rue',
  labelSummaryField: 'Champ du sommaire',
  labelSummaryReport: 'Rapport de synthèse',
  labelSummaryReportFormSubmissions:
    'Rapport de synthèse + soumissions de formulaires',
  labelSwitch: 'Basculer',
  labelTarget: 'Cible',
  labelTargetPercentage: 'Cible (%)',
  labelAboveTarget: 'Au-dessus de la cible',
  labelBelowTarget: 'En dessous de la cible',
  labelNoTarget: 'Pas de cible',
  labelNoAccess: `Pas d'accès`,
  labelOnTarget: 'Sur la cible',
  labelTask: 'Tâche',
  labelTaskTemplates: 'Modèles de tâches',
  labelTasks: 'Tâches',
  labelTemplate: 'Modèle',
  labelTemplates: 'Modèles',
  labelText: 'Texte',
  labelTime: 'Heure',
  labelTimegate: 'Timegate',
  labelTempla: 'Templa',
  labelTimegateSIN: 'SIN',
  labelTimegateEmployeePIN: 'Code PIN Timegate',
  labelTimeMins: 'Heure (mins)',
  labelTimeline: 'Chronologie',
  labelTimestamp: 'Horodatage',
  labelTimezone: 'Fuseau horaire',
  labelTitle: 'Titre',
  labelTitleDateRange: '{{title}} de {{from}} à {{to}}',
  labelTo: 'À',
  labelType: 'Taper',
  labelUnknownLocation: 'Emplacement inconnu',
  labelUnlimited: 'Illimité',
  labelUpdatedAudit: 'Audit mis à jour',
  labelUpdatedIssue: 'Problème mis à jour, non attribués à moi',
  labelUpdatedTask: 'Tâche mise à jour',
  labelUnknownArea: 'Zone inconnue',
  labelUnknownLocationGroup: "Groupe d'emplacement inconnu",
  labelUnknownSignal: 'Signal inconnu',
  labelUseLocationServiceHours:
    "Utiliser les heures de services de l'emplacement",
  labelUser: 'Utilisateur',
  labelUserRoles: 'Rôles des utilisateurs',
  labelUserCanSkip: "L'utilisateur peut sauter",
  labelUserId: "Identifiant d'utilisateur",
  labelUsername: "Nom d'utilisateur",
  labelUsers: 'Utilisateurs',
  labelView: 'Affichage',
  labelVisit: 'Visite',
  labelVisits: 'Visites',
  labelWebOnly: 'Web seulement',
  labelWeeks: 'Semaines',
  labelWeight: 'Poids',
  labelWinTeam: 'WinTeam',
  labelWinTeamEnabled: 'WinTeam activé',
  labelXLocations: '{{ count }} Emplacements',
  labelXLocationGroups: '{{ count }} Groupes de localisation',
  labelYears: 'Ans',
  labelYes: 'Oui',
  labelZeroLocations: '0 Emplacement',
  labelZone: 'Zone',

  'leftPanel.locationGroupView.title': `Sélectionnez un nouveau groupe d'emplacements à attribuer à l'emplacement :`,

  'locationGroup.error.addLocations':
    "Impossible d'ajouter {{errorCount}} emplacements",
  'locationGroup.error.location': 'Erreur de localisation',
  'locationGroup.error.locationDeleted': 'Cet emplacement a été supprimé',
  'locationGroup.error.locationDeletedTitle': 'Emplacement supprimé',
  'locationGroup.error.locationAtMaxGroups':
    "Cet emplacement ne peut pas être ajouté à d'autres groupes",
  'locationGroup.error.locationAtMaxGroupsTitle': "Limite d'emplacement",
  'locationGroup.error.groupAtMaxLocations':
    "Ce groupe ne peut plus détenir d'emplacements",
  'locationGroup.error.groupAtMaxLocationsTitle': 'Limite de groupe',
  'locationGroup.crud.tooltip.message': `Les modifications apportées au groupe d'emplacement s'appliqueront uniquement aux futures soumissions de formulaires. Par exemple, les tâches soumises au même emplacement ne s'afficheront qu'une fois l'emplacement ajouté au groupe d'emplacements.`,
  'locationGroup.crud.tooltip.title': `Enregistrement des groupes d'emplacements`,

  'locationPanel.filter.allLocations': 'Tous les emplacements',
  'locationPanel.filter.closed': 'Fermé',
  'locationPanel.filter.open': 'Ouvert',
  'locationPanel.filter.openActive': 'Ouvert/actif',
  'locationPanel.filter.openInactive': 'Ouvert/inactif',
  'locationPanel.sort.locationName': "Nom de l'emplacement",
  'locationPanel.sort.userCount': 'Décompte des utilisateurs',
  'locationPanel.status.closed': 'FERMÉ',
  'locationPanel.status.open': 'OUVERT',
  'locationPanel.userCount': '{{count}} utilisateur en ligne',
  'locationPanel.userCount_plural': '{{count}} utilisateurs en ligne',
  'location.serviceHoursTooltip.message': `Leur configuration est utile à des fins de surveillance, car elle détermine quand une activité est prévue à cet endroit (par exemple, pour les horaires).`,
  'location.serviceHoursTooltip.title': `Heures de Service`,

  'login.applicationIdTooltip.message':
    "Votre ID d'application est votre identificateur unique de l'application. Il s'agit d'un seul mot en lettres minuscules sans espace (par ex., « nom de l'entreprise »). Si vous ne vous rappelez pas de votre ID d'application, contactez support@lighthouse.io.",
  'login.applicationIdTooltip.title': "ID d'application",
  'login.error.noEmailAddress': 'Veuillez saisir votre adresse courriel',
  'login.footerMessage': 'Vous débutez avec Lighthouse.io?',
  'login.header.applicationSignIn': 'Connectez-vous à votre application',
  'login.header.forgotPassword': 'Réinitialisez votre mot de passe',
  'login.header.signIn': 'Se connecter',
  'login.requestAccount': 'Demander le compte',
  'login.success.resetPassword':
    'Votre mot de passe a été réinitialisé. Suivez les instructions dans le courriel que nous vous avons envoyé {{username}}',
  'login.whatIsMyApplicationId': "Quelle est mon ID d' application?",
  'logout.header.loggingOut': 'Déconnexion en cours',

  'map.tooltip.clickToPlace': 'Cliquez pour placer sur la carte',
  'map.tooltip.invalidText': 'Continuer à tracer',
  'map.tooltip.new.auditentries': 'Nouvel audit (cliquez pour repositionner)',
  'map.tooltip.new.issue': 'Nouveau problème (cliquez pour repositionner)',
  'map.tooltip.new.signal': 'Nouveau signal (cliquez pour repositionner)',
  'map.tooltip.new.taskentries': 'Nouvelle tâche (cliquez pour repositionner)',
  'map.tooltip.outsideLocation':
    "Doit être à l'intérieur des limites du parent",
  'map.tooltip.startText': 'Cliquez sur la carte pour commencer à tracer',
  'map.tooltip.validText': 'Cliquez sur le premier point pour terminer',
  'mapOptions.legend': 'Légende',
  'mapOptions.legendItem.active': 'Actif (< 5 m)',
  'mapOptions.legendItem.activeUserCount': 'Décompte des utilisateurs actifs',
  'mapOptions.legendItem.audit': 'Audit',
  'mapOptions.legendItem.clusterWithLocation': 'Grappe avec emplacement',
  'mapOptions.legendItem.clusterWithoutLocation': 'Grappe sans emplacement',
  'mapOptions.legendItem.inactive': 'Inactif (> 1 h)',
  'mapOptions.legendItem.issue': 'Problème',
  'mapOptions.legendItem.recent': 'Récent (1 h)',
  'mapOptions.legendItem.task': 'Tâche',
  'mapOptions.legendItem.userInVehicle': 'Utilisateur dans le véhicule',
  'mapOptions.newAudit': 'Nouvel audit',
  'mapOptions.newIssue': 'Nouveau problème',
  'mapOptions.newLocation': 'Nouvel emplacement',
  'mapOptions.newTask': 'Nouvelle tâche',
  'mapOptions.options': 'Options',
  'mapOptions.optionsItem.audits': 'Audits',
  'mapOptions.optionsItem.geofences': 'Périmètres virtuels',
  'mapOptions.optionsItem.issues': 'Problèmes',
  'mapOptions.optionsItem.locations': 'Emplacements',
  'mapOptions.optionsItem.map': 'Carte',
  'mapOptions.optionsItem.people': 'Personnes',
  'mapOptions.optionsItem.satellite': 'Satellite',
  'mapOptions.optionsItem.signals': 'Signaux',
  'mapOptions.optionsItem.tasks': 'Tâches',
  'mapOptions.optionsItem.tooltips': 'Infobulles',
  'mapOptions.optionsItem.beacon': 'Balises',
  'mapOptions.optionsItem.qrcode': 'QR',
  'mapOptions.optionsItem.nfc': 'NFC',

  'modal.delete.area.title': 'Supprimer cette zone ?',
  'modal.delete.area.message':
    'Voulez-vous vraiment supprimer cette zone ?\n\nLa suppression de cet étage supprimera toutes les zones et tous les signaux contenus dans cette zone. Cette action est irréversible.',
  'modal.delete.audit.title': 'Supprimer cet audit ?',
  'modal.delete.audit.message': `Voulez-vous vraiment supprimer cet audit ?\n\nLa suppression de l'audit entraîne la suppression de la soumission. Cette action est irréversible.`,
  'modal.delete.building.title': 'Supprimer ce bâtiment ?',
  'modal.delete.building.message':
    'Voulez-vous vraiment supprimer ce bâtiment ?\n\nLa suppression de ce bâtiment supprimera toutes les zones, tous les étages et tous les signaux contenus dans ce bâtiment. Cette action est irréversible.',
  'modal.delete.floor.title': 'Supprimer cet étage ?',
  'modal.delete.floor.message':
    'Êtes-vous sûr de vouloir supprimer cet étage ?\n\nLa suppression de cet étage supprimera toutes les zones et tous les signaux contenus dans cet étage. Cette action est irréversible.',
  'modal.delete.issue.title': 'Supprimer ce problème ?',
  'modal.delete.issue.message':
    'Êtes-vous sûr de vouloir supprimer ce problème ?\n\nLa suppression du problème entraîne la suppression de la soumission. Cette action est irréversible.',
  'modal.delete.location.title': 'Supprimer cet établissement ?',
  'modal.delete.location.message':
    'Êtes-vous sûr de vouloir supprimer cet emplacement ?\n\nLa suppression de cet emplacement supprimera toutes les zones et tous les signaux contenus dans cet emplacement. Cette action est irréversible.',
  'modal.delete.locationGroup.title': 'Supprimer ce groupe d emplacements ?',
  'modal.delete.locationGroup.message':
    "Êtes-vous sûr de vouloir supprimer ce groupe d'emplacements ?\n\nLa suppression du groupe d'emplacements supprime la possibilité de filtrer les modèles, de filtrer les rapports et d'attribuer le groupe à des modèles. Il ne supprime pas les emplacements ni ne les supprime d'autres groupes d'emplacements.",
  'modal.delete.messageGroup.title': 'Supprimer ce groupe de messages ?',
  'modal.delete.messageGroup.message':
    'Êtes-vous sûr de vouloir supprimer ce groupe de messages ?',
  'modal.delete.role.title': 'Supprimer ce rôle ?',
  'modal.delete.role.message':
    'Êtes-vous sûr de vouloir supprimer ce rôle ?\n\nLes rôles ne peuvent pas être supprimés tant que des utilisateurs actifs y sont affectés. La suppression du rôle supprime la possibilité de filtrer les rapports par ce rôle. La suppression du rôle ne supprime pas les données soumises précédemment.',
  'modal.delete.schedule.title': 'Supprimer ce programme ?',
  'modal.delete.schedule.message':
    'Êtes-vous sûr de vouloir supprimer cette planification ?\n\nLa suppression de la planification supprimera toutes les instances actives et futures de cette planification.',
  'modal.delete.signal.title': 'Supprimer ce signal ?',
  'modal.delete.signal.message':
    'Êtes-vous sûr de vouloir supprimer cette planification ?\n\nLa suppression de la planification supprimera toutes les instances actives et futures de cette planification.',
  'modal.delete.signals.title': 'Supprimer ces signaux ?',
  'modal.delete.signals.message':
    "Voulez-vous vraiment supprimer ces signaux ?\n\nLa suppression de ces signaux entraînera la suppression de l'identifiant de balise NFC ou des codes QR associés (selon le cas). Les codes QR ne peuvent pas être réutilisés une fois supprimés. Les balises NFC peuvent être réaffectées à de nouveaux signaux selon les besoins.",
  'modal.delete.task.title': 'Supprimer cette tâche ?',
  'modal.delete.task.message':
    'Voulez-vous vraiment supprimer cette tâche ?\n\nLa suppression de la tâche entraîne la suppression de la soumission. Cette action est irréversible.',
  'modal.delete.template.title': 'Supprimer ce modèle ?',
  'modal.delete.template.message':
    'Êtes-vous sûr de vouloir supprimer ce modèle ?inLa suppression du modèle supprime la possibilité de filtrer les rapports en fonction de ce modèle. La suppression du modèle ne supprime pas les données soumises précédemment.',
  'modal.delete.user.title': 'Supprimer cet utilisateur?',
  'modal.delete.user.message': `Êtes-vous sûr de vouloir supprimer cet utilisateur?\n\nLa suppression de l'utilisateur supprime la possibilité de filtrer les rapports par cet utilisateur. La suppression de l'utilisateur ne supprime pas les données précédemment soumises.`,

  'navigation.admin': 'Admin',
  'navigation.admin.applications': 'Applications',
  'navigation.logout': 'Déconnexion',
  'navigation.maps': 'Carte',
  'navigation.messages': 'Messages',
  'navigation.reports': 'Rapports',
  'navigation.reports.audits': 'Audits',
  'navigation.reports.dashboard': 'Tableau de bord',
  'navigation.reports.events': 'Événements',
  'navigation.reports.exceptions': 'Exceptions',
  'navigation.reports.exports': 'Exportations',
  'navigation.reports.issues': 'Problèmes',
  'navigation.reports.locations': 'Emplacements',
  'navigation.reports.shifts': 'Quarts de travail',
  'navigation.reports.tasks': 'Tâches',
  'navigation.schedules': 'Horaires',
  'navigation.schedules.work': 'Travail',
  'navigation.settings': 'Paramètres',
  'navigation.settings.profile': 'Profil',
  'navigation.settings.roles': 'Rôles et permissions',
  'navigation.settings.users': 'Utilisateurs',
  'navigation.setup': 'Configuration',
  'navigation.setup.locations': 'Emplacements',
  'navigation.setup.location-groups': "groupes d'emplacements",
  'navigation.setup.loops': 'Temps des boucles',
  'navigation.setup.signals': 'Signaux',
  'navigation.templates': 'Modèles',
  'navigation.templates.audits': 'Audits',
  'navigation.templates.content': 'Modèles de contenu',
  'navigation.templates.entries': 'Saisies de contenu',
  'navigation.templates.issues': 'Problèmes',
  'navigation.templates.tasks': 'Tâches',

  'panel.header.loadingTemplates': 'Chargement des modèles',
  'panel.header.selectTemplate': 'Sélectionner le modèle',

  'placeholder.NfcTagId': 'ID de balise NFC',
  'placeholder.advertisingInterval': 'Intervalle de publicité',
  'placeholder.allLocations': 'Tous les emplacements',
  'placeholder.allZones': 'Toutes les zones',
  'placeholder.auditTitle': "Titre de l'audit",
  'placeholder.beaconMajor': 'Beacon Majeur',
  'placeholder.beaconMinor': 'Beacon Mineur',
  'placeholder.chooseAGroupName': 'Choisissez un nom de groupe’,',
  'placeholder.chooseOptionOrAddNewOption':
    'Choisir une option ou ajouter une nouvelle option',
  'placeholder.city': 'Ville',
  'placeholder.description': 'Description',
  'placeholder.email': 'courriel',
  'placeholder.enterApplicationId': "saisir l'ID d'application",
  'placeholder.enterMessage': 'Saisir un message...',
  'placeholder.enterMobileNumber': 'Saisir le numéro de cellulaire',
  'placeholder.firstName': 'prénom',
  'placeholder.floors': 'Planchers',
  'placeholder.issueTitle': 'Titre du problème',
  'placeholder.label': 'Étiquette',
  'placeholder.lastName': 'nom de famille',
  'placeholder.latitude': 'Latitude',
  'placeholder.longitude': 'Longitude',
  'placeholder.mins': 'Mins',
  'placeholder.mobile': 'mobile',
  'placeholder.name': 'Nom',
  'placeholder.nameOfLocationGroup': "Nom du groupe d'emplacements",
  'placeholder.password': 'password',
  'placeholder.postCode': 'Code postal',
  'placeholder.reference': 'Référence',
  'placeholder.roleName': 'Nom du rôle',
  'placeholder.rssi': 'RSSI',
  'placeholder.search': 'Rechercher',
  'placeholder.searchLocations': 'Rechercher des emplacements',
  'placeholder.searchOrSelect': 'Rechercher ou sélectionner',
  'placeholder.search_ellipsis': 'Rechercher...',
  'placeholder.select': 'Sélectionner',
  'placeholder.selectANotificationMethod':
    'Sélectionner une méthode de notification',
  'placeholder.selectAccess': "Sélectionner l'accès",
  'placeholder.selectAnOption': 'Sélectionnez une option',
  'placeholder.selectArea': 'Sélectionner la région',
  'placeholder.selectAreas': 'Sélectionner les régions',
  'placeholder.selectAssignedUsers': 'Sélectionner les utilisateurs assignés',
  'placeholder.selectAuditTemplate': "Sélectionner le modèle d'audit",
  'placeholder.selectCountry': 'Sélectionner le pays',
  'placeholder.selectDateFormat': 'Sélectionner le format de la date ',
  'placeholder.selectDates': 'Sélectionner les dates',
  'placeholder.selectFilterCollection': 'Sélectionner la collection de filtres',
  'placeholder.selectFilters': 'Sélectionner les filtres',
  'placeholder.selectIssueStatus': 'Sélectionner le statut du problème',
  'placeholder.selectIssueTemplate': 'Sélectionner le modèle de problème',
  'placeholder.selectLanguage': 'Sélectionner la langue',
  'placeholder.selectLocation': "Sélectionner l'emplacement",
  'placeholder.selectLocationGroup': `Sélectionnez un groupe d'emplacements`,
  'placeholder.selectLocations': 'Sélectionner les emplacements',
  'placeholder.selectLocationsLocationGroups':
    'Sélectionnez un emplacement (max. 1 000)',
  'placeholder.selectOption': "Sélectionner l'option",
  'placeholder.selectOrDropPin': 'Sélectionner ou placer une balise',
  'placeholder.selectOrSearch': 'Sélectionner ou rechercher',
  'placeholder.selectParticipants': 'Sélectionner les participants',
  'placeholder.selectRole': 'Sélectionner le rôle',
  'placeholder.selectRoles': 'Sélectionner les rôles',
  'placeholder.selectShiftType': 'Sélectionner le type de quart de travail',
  'placeholder.selectTaskTemplate': 'Sélectionner le modèle de tâche',
  'placeholder.selectTemplate': 'Sélectionner le modèle',
  'placeholder.selectTime': "Sélectionner l'heure",
  'placeholder.selectTimeFormat': "Sélectionner le format de l'heure",
  'placeholder.selectTimezone': 'Sélectionner le fuseau horaire',
  'placeholder.selectType': 'Sélectionner le type',
  'placeholder.selectUser': 'Sélectionner l’utilisateur',
  'placeholder.selectUserRoles': "Sélectionnez les rôles d'utilisateur",
  'placeholder.selectUsers': 'Sélectionner les utilisateurs',
  'placeholder.signalLabel': 'Étiquette de signal',
  'placeholder.signalType': 'Type de signal',
  'placeholder.state': 'État',
  'placeholder.streetAddress': 'Adresse municipale',
  'placeholder.target': 'Cible',
  'placeholder.taskTitle': 'Titre de la tâche',
  'placeholder.timegateEmployeePIN': 'PIN',
  'placeholder.transmittingPower': "Puissance d'émission",
  'placeholder.username': "nom d'utilisateur",
  'placeholder.uuid': 'UUID',
  'placeholder.winTeam': 'WinTeam',

  privacy: 'Confidentialité',

  'prompts.error.applicationError':
    "Erreur de l'application. Veuillez contacter l'assistance.",
  'prompts.error.fieldToComplete': 'Vous avez {{count}} champ à remplir',
  'prompts.error.fieldToComplete_plural':
    'Vous avez {{count}} champs à remplir',
  'prompts.error.pleaseCompleteAllFields': 'Veuillez remplir tous les champs',
  'prompts.error.validation': 'Vous avez {{count}} erreur de validation',
  'prompts.error.validation_plural':
    'Vous avez {{count}} erreurs de validation',
  'prompts.unsaved.youHaveUnsavedChanges':
    'Vous avez des changements non sauvegardés',
  'prompts.warning.resolveWarnings': 'Veuillez résoudre ces mises en garde',

  'roles.adhocTooltip.message':
    "Enregistrer l'emplacement lorsque les utilisateurs effectuent des actions précises en utilisant l'application mobile. Les actions comprennent commencer/mettre fin au quart de travail, scan du code QR, soumettre la tâche, soumettre le problème et soumettre l'audit.",
  'roles.adhocTooltip.title': "Enregistrer l'emplacement des actions",
  'roles.passiveTooltip.message':
    "Enregistrer l'emplacement continuellement en arrière-plan, même si l'application mobile est fermée",
  'roles.passiveTooltip.title': "Toujours enregistrer l'emplacement",

  'schedules.locationsTooltip.title': 'Sélectionnez des emplacements',
  'schedules.locationsTooltip.message': `Attribuer au(x) emplacement(s) approprié(s). Si vous sélectionnez plusieurs emplacements, vous ne pouvez pas également les attribuer aux signaux et aux zones.`,
  'schedules.areasTooltip.title': 'Sélectionnez Zone(s) et signal(s)',
  'schedules.areasTooltip.message': `Attribuez à la ou aux zones et aux signaux appropriés.`,
  'schedules.enableTooltip.title': `Option d'activation de la planification`,
  'schedules.enableTooltip.message': `Activé déroulant jusqu'à Oui. Si ce champ est défini sur Non, cet élément de travail planifié ne sera pas actif.`,
  'schedules.usersTooltip.title': 'Sélectionnez les Utilisateurs',
  'schedules.usersTooltip.message': `Attribuer aux utilisateurs appropriés. Si le planning est attribué à plusieurs utilisateurs, il ne doit être rempli que par l'un de ces utilisateurs.`,
  'schedules.repeatTooltip.title': `Sélectionnez l'option de Répétition`,
  'schedules.repeatTooltip.message': `L'option <i>Aucune répétition</i> est une occurrence unique et utilise par défaut les heures de service de localisation.<br/>L'option <i>Répéter à l'heure depuis la fin de la dernière activité</i> base la minuterie de démarrage sur l'heure à laquelle l'activité s'est terminée.<br/>L'option <i>Répéter à des intervalles de temps définis</i> base l'heure de début sur des intervalles réguliers à partir de l'heure d'ouverture du service de l'emplacement.`,
  'schedules.rolesTooltip.title': 'Sélectionnez le(s) Rôle(s)',
  'schedules.rolesTooltip.message': `Attribuez aux rôles appropriés. Si le planning est affecté à plusieurs rôles, il ne doit être rempli que par l'un de ces utilisateurs.`,
  'schedules.typeTooltip.title': `Type d'Horaire`,
  'schedules.typeTooltip.message': `Sélectionnez le menu déroulant Type et sélectionnez Visite, Audit ou Tâche. La visite nécessite un événement de localisation pour se terminer, tandis que l'audit / la tâche nécessite la soumission de l'audit / de la tâche sélectionné.`,
  'schedule.startTooltip.message': `Définissez la date de début de la planification, en fonction de l'heure à laquelle vous souhaitez que cet élément démarre. Notez que si vous sélectionnez Aujourd'hui, l'horaire commencera exactement à l'heure indiquée (par exemple, 13 h 00 si vous enregistrez à 13 h 00). Si vous souhaitez que les programmes commencent en début de journée, sélectionnez une date ultérieure (par exemple, demain).
  S'il y a des heures spécifiques pour que ce travail soit effectué, décochez la case Utiliser les heures de service de localisation, cliquez sur le bouton Ajouter des heures de service et configurez les options Jour/Date, Options, Heure d'ouverture et Heure de fermeture.`,
  'schedule.startTooltip.title': `Opción de programa de inicio`,

  'searchMenu.footerMessage':
    'Rechercher et sélectionner par emplacement ou adresse',
  'searchMenu.header.recent': 'Récent',
  'searchMenu.noRecentLocations': 'Aucun emplacement récent',
  'searchMenu.noResultsFound': 'Aucun emplacement trouvé',

  'searchPanel.noSearch':
    'Saisir le texte de recherche pour générer des résultats',
  'searchPanel.userLastSeen': 'Vue la dernière fois - {{datetime}}',

  'setup.bulkSelect':
    'Cliquez pour sélectionner tous les signaux affichés sur la page.',
  'setup.clearSelection': 'Effacer la sélection.',
  'setup.selectMessage': '{{selectedCount}} sur {{totalCount}} sélectionné.',

  switchApplication: "Basculer d'application",

  'taskPanel.header.newTask': 'Nouvelle tâche',
  'taskPanel.header.task': 'Tâche',
  'template.fieldReference.message':
    'Une valeur à utiliser comme référence pour les exports ou lien vers un système externe.',
  'template.fieldReference.title': 'Référence',
  'template.fieldTooltip.message':
    'Un champ représente une entrée unique dans votre formulaire.',
  'template.fieldTooltip.title': 'Champ',
  'template.groupNameTooltip.message':
    'Un groupe est un mécanisme organisateur pour le groupement des ensembles de champs de formulaire.',
  'template.groupNameTooltip.title': 'Nom du groupe',
  'template.locationGroup.title': `Attribution de groupes d'emplacement`,
  'template.locationGroup.message': `Les attributions de groupes d'emplacement s'appliqueront uniquement aux futures soumissions de formulaires. Par exemple, les tâches soumises au même emplacement ne s'afficheront sous un groupe d'emplacements qu'une fois le modèle ajouté.`,
  'template.requiredTooltip.message':
    "Oui - La question requiert une réponse. Le formulaire ne peut pas être soumis si des questions demeurent sans réponse. Non - Les utilisateurs peuvent sauter la question. Les questions sautées n'auront pas d'impact sur la note de l'audit",
  'template.requiredTooltip.title': 'Requis',
  'template.repeatTooltip.message': `Lorsque l'option de groupe reproductible est activée sur un modèle de formulaire, elle permet à l'employé remplissant le formulaire de répéter le groupe de formulaires plusieurs fois.`,
  'template.repeatTooltip.title': `Sélectionnez Répétable`,
  'template.scoreTooltip.message':
    "La valeur de la note représente le résultat de la question dans son propre contexte. Voyez-le comme une note en pourcentage sur une échelle de 0 à 1, par ex., Passe = 1, OK = 0,5 et Échec = 0.'",
  'template.scoreTooltip.title': 'Note',
  'template.summaryFieldTooltip.message':
    "Les champs de sommaire vous permettre d'afficher des informations additionnelles dans les sommaires d'activité. Vous pouvez sélectionner jusqu'à {{MAX_SUMMARY_FIELDS}} champs de sommaire par modèle.",
  'template.summaryFieldTooltip.title': 'Champ de sommaire',
  'template.typeTooltip.message':
    "Vous permet de préciser le type de saisie à afficher. « Texte » permet une saisie de texte simple, « Sélectionner » permet des options prédéfinies multiples, « Basculer » permet une saisie par case à cocher, « Média » permet le chargement d'un média.'",
  'template.typeTooltip.title': 'Type',
  'template.weightTooltip.message':
    "La valeur de pondération représente la pondération de la note de cette question par rapport aux autres questions. Elle doit être une valeur numérique positive. Si la question compte plus que les autres, donnez-lui une plus grande valeur. Nous multiplions la valeur pas la note pour la question lorsque l'audit est soumis pour obtenir un total global.",
  'template.weightTooltip.title': 'Pondération',
  'template.statusBanner.locked':
    'Ce modèle est actuellement verrouillé et ne peut être modifié par personne',
  'template.statusBanner.unlocked':
    "Ce modèle est actuellement déverrouillé et modifiable par n'importe qui",
  'template.statusBanner.lockButtonLock': 'Bloquer',
  'template.statusBanner.lockButtonUnlock': 'Ouvrir',

  terms: 'Conditions',
  textAbilityToCreateMessageGroups:
    'Possibilité de créer des groupes de messages',
  textAbilityToGenerateDownloadDataExports:
    'Possibilité de générer et de télécharger des exportations de données',
  textAbilityToManageFormTemplates:
    "Possibilité d'afficher et de gérer des modèles de formulaires",
  textAbilityToManageLoopTimes:
    "Possibilité d'afficher et de gérer les temps de boucle. L'accès aux temps de boucle individuels est défini au niveau de l'utilisateur",
  textAbilityToRestrictIssueClosing: `Empêcher la possibilité de changer l'état du problème en "fermé"`,
  textAbilityToSetupPages:
    "Possibilité d'afficher la configuration et les pages associées",
  textAbilityToShareForms:
    'Possibilité de partager des formulaires depuis un mobile',
  textAbilityToViewApplicationRoles:
    "Possibilité d'afficher les rôles d'application et d'ajouter / modifier / supprimer des rôles et des autorisations",
  textAbilityToViewApplicationUser:
    "Possibilité d'afficher l'utilisateur de l'application et d'ajouter / modifier / supprimer des utilisateurs",
  textAbilityToViewCreateEditLocations:
    "Possibilité d'afficher, de créer et de modifier des emplacements. L'accès aux emplacements individuels est attribué au niveau de l'utilisateur.",
  textAbilityToViewCreateEditSignals:
    "Possibilité d'afficher, de créer et de modifier les signaux, y compris l'attribution des signaux à partir de l'application mobile",
  textAbilityToViewCreateEditWork:
    "Possibilité d'afficher, de créer et de modifier le travail planifié",
  textAbilityToViewDailyLocationReports:
    "Possibilité d'afficher les rapports de localisation quotidiens",
  textAbilityToViewExceptionsTriggered:
    'Possibilité de visualiser les exceptions déclenchées',
  textAbilityToViewManage: 'Capacité de visualiser et de gérer',
  textAbilityToViewManageEvents:
    "Possibilité d'afficher et de gérer les événements",
  textAbilityToViewMapTimeline:
    "Possibilité d'afficher la chronologie de la carte et les données de localisation historiques",
  textAbilityToViewMaps: "Possibilité d'afficher des cartes",
  textAbilityToViewRecentActivity:
    "Possibilité de consulter le flux d'activités récentes",
  textAbilityToViewReportsDashboard:
    "Possibilité d'afficher le tableau de bord des rapports",
  textAbilityToViewSendMessages:
    "Possibilité d'afficher et d'envoyer des messages",
  textAbilityToViewSignalTesting:
    'Possibilité de visualiser les tests de signal',
  textShiftsPermissionDescription:
    "Possibilité d'afficher les quarts de travail soumis sur le Web et de créer des quarts de travail sur mobiles",
  textAllAudits: '...tous les audits',
  textAllIssues: '...tous les problèmes',
  textAllTasks: '...Toutes les tâches',
  textAssignedTo: '/assigné à',
  textCreateYourFirstGroup: 'Créez votre premier groupe',
  textDoNotBelongToAnyMessageGroups:
    "Vous n'appartenez actuellement à aucun groupe de messages.",
  textLoading: 'Chargement...',
  textNoResults: 'Aucun résultat',
  textNow: 'à présent',
  textOnlyAudits:
    '...uniquement les audits créés par{{assigneesDescription}} cet utilisateur',
  textOnlyIssues:
    '...uniquement les problèmes créés par{{assigneesDescription}} cet utilisateur',
  textOnlyTasks:
    '...uniquement les tâches créées par{{assigneesDescription}} cet utilisateur',

  textResendAllInvites: 'Renvoyer les invitations',
  textResendAllInvitesConfirm:
    'Cela renverra les invitations à tous les utilisateurs qui ont actuellement des invitations en attente. Voulez-vous vraiment renvoyer les invitations à {{invitedUsersCount}} utilisateurs ?',
  textResendAllInvitesSuccess:
    'Les invitations sont renvoyées. Cela peut prendre quelques minutes.',
  textResendAllInvitesError:
    'Un problème est survenu lors du renvoi des invitations. Veuillez réessayer.',
  textResendAllInvitesCooldowHoursAndMinutes:
    'Le renvoi des invitations à tous les utilisateurs avec une invitation en attente peut être effectué une fois toutes les 8 heures. Cette action peut être répétée en {{hoursRemaining}} heure et {{minutesRemaining}} minutes',
  textResendAllInvitesCooldowMinutes:
    'Le renvoi des invitations à tous les utilisateurs avec une invitation en attente peut être effectué une fois toutes les 8 heures. Cette action peut être répétée en {{minutesRemaining}} minutes',
  textUnknown: 'Inconnu',

  titleAddUsers: 'Ajouter des utilisateurs',
  titleGeoSettings: 'Paramètres géographiques',
  titleNewSignal: 'Nouveau signal',
  titleNewUsers: 'Nouveaux utilisateurs',

  'tooltip.message.Uuid':
    'Une chaîne UUID Spec v4 utilisée pour différencier un grand groupe de balises associées.',
  'tooltip.message.advertisingInterval':
    'Le délai entre les paquets publicitaires diffusés. Cela devrait être un entier positif.',
  'tooltip.message.beaconMajor':
    'Utilisé pour regrouper un ensemble de balises associé (par exemple, toutes les balises en un seul endroit).',
  'tooltip.message.beaconMajorAlt':
    'Un entier positif utilisé pour différencier un plus petit sous-ensemble de balises au sein du plus grand groupe.',
  'tooltip.message.beaconMinor':
    'Utilisé pour identifier les balises individuelles (par exemple, une seule zone à un seul endroit).',
  'tooltip.message.beaconMinorAlt':
    'Entier positif utilisé pour identifier les balises individuelles.',
  'tooltip.message.beaconUuid':
    "Identificateur de balise unique qui aide l'application mobile à distinguer vos balises des autres balises. Vous devez utiliser le même UUID pour toutes les balises associées à une application particulière.",
  'tooltip.message.document':
    'Types de fichiers pris en charge, PDF, DOC, DOCX',
  'tooltip.message.jobNumber':
    'Pour les clients WinTeam, vous pouvez référencer le numéro de travail associé',
  'tooltip.message.locationGroupsRestrictions':
    'Sélectionnez entre 0 et {{locationsPerGroupMax}} emplacements pour chaque groupe. Les emplacements individuels peuvent être inclus dans un maximum de {{groupsPerLocationMax}} groupes.',
  'tooltip.message.locationXRefCode':
    'Pour les clients Dayforce, vous pouvez référencer le code de localisation associé',
  'tooltip.message.mapZoomLevel':
    "Représente le zoom par défaut sur la carte ou le plan d'étage. Un zoom de carte standard serait de 17 ou plus.",
  'tooltip.message.media':
    'Types de fichiers pris en charge, PNG, JPG, JPEG, MP4',
  'tooltip.message.nfcTagId':
    "Toutes les balises NFC sont fabriquées avec un identifiant de balise unique. Il s'agit généralement de 14 caractères hexadécimaux (par exemple, 04 9C 64 D2 45 2B 80) et ne peuvent pas être modifiés. Ces identifiants de balise sont attribués au signal pour identifier la balise lors de la numérisation par l'application mobile Lighthouse.",
  'tooltip.message.photo':
    'Types de fichiers pris en charge, PNG, JPG, JPEG, MP4',
  'tooltip.message.shiftGeofenceRestriction': `Confirmer l'emplacement - nécessitera la sélection d'un emplacement avant qu'un quart de travail puisse commencer. L'emplacement de fin de quart héritera de cet emplacement.
  
  Confirmer la clôture géographique - obligera les utilisateurs à se trouver dans la clôture géographique d'un emplacement lors du démarrage d'un quart de travail.

  Si les deux sont activés, leur GPS doit se trouver dans la limite géographique de l'emplacement sélectionné au début du quart de travail.`,
  'tooltip.message.signalStrengthIndicator':
    "Une valeur numérique indiquant la force du signal de la balise tel qu'il apparaît sur l'appareil récepteur.",
  'tooltip.message.timezone':
    "Le fuseau horaire est le fuseau horaire local de l'emplacement sélectionné.",
  'tooltip.message.transmittingPower':
    "La puissance avec laquelle le signal quitte l'antenne de la balise. Généralement entre -30 dBm et +4 dBm",
  'tooltip.reference.message': `Les champs de référence sont des champs de texte libre, généralement utilisés pour prendre en charge les intégrations avec des solutions externes. Ils n'apparaissent pas dans l'application mobile, les rapports ou les exportations.`,
  'tooltip.reference.title': 'Champ de référence',
  'tooltip.message.confirmLocation':
    "Ce paramètre obligera les utilisateurs mobiles à confirmer leur emplacement lors du démarrage d'un quart de travail. Pour les démarrages de quart automatiques, ils doivent confirmer un emplacement pour se connecter. Pour les débuts de quart de travail manuels, ils doivent sélectionner un emplacement pour commencer le passage.",
  'tooltip.message.timegateSIN': `Pour les clients Timegate, vous pouvez référencer le site associé à l'aide du numéro d'identification du site Timegate`,
  'tooltip.message.templaSiteCode':
    'Pour les clients Templa, vous pouvez référencer le site concerné en utilisant le code du site Templa',
  'tooltip.title.Uuid': 'UUID',
  'tooltip.title.advertisingInterval': 'Intervalle de publicité',
  'tooltip.title.apiError': 'Erreur API',
  'tooltip.title.beaconMajor': 'Beacon Majeur',
  'tooltip.title.beaconMinor': 'Beacon Mineur',
  'tooltip.title.beaconUuid': 'Beacon UUID',
  'tooltip.title.document': 'Téléchargement de fichiers',
  'tooltip.title.jobNumber': 'Numéro de tâche WinTeam',
  'tooltip.title.locationGroupsRestrictions':
    "Restrictions des groupes d'emplacements",
  'tooltip.title.locationXRefCode': 'Code de localisation Dayforce',
  'tooltip.title.mapZoomLevel': 'Niveau de zoom de la carte',
  'tooltip.title.media': 'Téléchargement multimédia',
  'tooltip.title.nfcTagId': 'ID de balise NFC',
  'tooltip.title.photo': 'Téléchargement de photos',
  'tooltip.title.shiftGeofenceRestriction':
    "Ajouter des restrictions au début d'un quart de travail",
  'tooltip.title.signalStrengthIndicator': 'Indicateur de force du signal reçu',
  'tooltip.title.timezone': 'Fuseau horaire',
  'tooltip.title.transmittingPower': "Puissance d'émission",
  'tooltip.title.validationError': 'erreur de validation',
  'tooltip.title.confirmLocation': 'Emplacement au début du quart',
  'tooltip.title.timegateSIN': "Numéro d'identification du site (SIN)",
  'tooltip.title.templaSiteCode': 'Le code du site',
  'tooltip.title.endShiftRestriction': 'Restricciones de fin de turno',
  'tooltip.message.endShiftRestriction':
    'Permita cualquier ubicación, restrinja a la ubicación de inicio de turno o use siempre la ubicación de inicio de turno para finalizar los turnos. Todas las opciones registrarán el GPS sin procesar del usuario y si estaban dentro de la geocerca de ubicación de inicio de turno',

  'users.authStrategyTooltip.message':
    "Si cette fonctionnalité est disponible pour votre organisation, veuillez choisir le fournisseur d'authentification des utilisateurs privilégié.",
  'users.authStrategyTooltip.title': "Fournisseur d'authentification",
  'users.dayforce.message':
    "Pour les clients Dayforce, vous pouvez faire référence au numéro de badge d'employé associé",
  'users.dayforce.employeeBadgeNumber': "Numéro de badge d'employé",
  'users.endShiftTooltip.message':
    "Si vous réglez « Mettre fin au quart de travail » à « Automatiquement », l'utilisateur mettre fin à un nouveau quart aussitôt qu'il se déconnecte de l'application mobile Lighthouse. Cela élimine le besoin de mettre fin aux quarts de travail manuellement sur l'appareil.",
  'users.endShiftTooltip.title': 'Mettre fin au quart de travail',
  'users.locationsTooltip.message': `Ajoutez des emplacements pour donner accès uniquement à des sites spécifiques. Ne pas spécifier un emplacement ou un groupe d'emplacements
  fournir un accès par défaut à tous.`,
  'users.locationsTooltip.title': 'Sélectionner les emplacements',
  'users.locationGroupsTooltip.message': `Ajoutez des groupes d'emplacements pour donner accès à tous les emplacements du groupe. Ne pas spécifier d'emplacement ou de groupe d'emplacements fournira un accès par défaut à tous`,
  'users.locationGroupsTooltip.title': `Sélectionnez les groupes d'emplacement`,
  'users.roleTooltip.message':
    "Sélectionner le type de rôle de l'utilisateur et cela appliquera les permissions de lecture et d'écriture associées. Vous pouvez configurer et gérer les rôles dans l'onglet « Rôles et permissions ».",
  'users.roleTooltip.title': 'Rôle',
  'users.startShiftTooltip.message':
    "Si vous réglez le début du quart de travail à « Automatiquement », l'utilisateur commencera un nouveau quart de travail aussitôt qu'il se connectera à l'application mobile Lighthouse. Cela élimine le besoin de commencer les quarts de travail manuellement sur l'appareil.'",
  'users.startShiftTooltip.title': 'Commencer le quart de travail',
  'users.timegateEmployeePINTooltip.message':
    "Pour les clients Timegate, vous pouvez faire référence au code PIN de l'employé associé.",
  'users.timegateEmployeePINTooltip.title': "Code PIN de l'employé Timegate",
  'users.winTeamEnabledTooltip.message':
    "Pour les clients WinTeam, vous pouvez activer ou désactiver l'intégration WinTeam pour cet utilisateur.",
  'users.winTeamEnabledTooltip.title': 'WinTeam activé',
  'users.winteamEmployeeNumberTooltip.message':
    "Pour les clients WinTeam, vous pouvez faire référence au numéro d'employé lié.",
  'users.winteamEmployeeNumberTooltip.title': "Numéro d'employé WinTeam",
  'users.locked.message':
    'Ce compte est bloqué. Veuillez entrer un mot de passe pour réinitialiser le compte.',

  'validation.maxLength': 'Doit contenir moins de {{maxLength}} caractères',
  'validation.numberOrDecimal':
    "La cible doit être un nombre positif jusqu'à 2 décimales",
  'validation.requiredField': 'Ce champ est requis',
  'validation.targetMaxError': 'La cible doit être inférieure ou égale à 100',
  'validation.targetMinError': 'La cible doit être supérieure ou égale à 0',
  'validation.wholeNumber': 'La cible doit être un nombre entier positif',

  'warning.userRole': `si vous n'attribuez pas votre propre rôle, vous n'aurez pas accès aux soumissions du formulaire.`,

  'work.errors.endAtTimeIsRequired': "L'heure de fin est un champ requis",
  'work.errors.endDateAfterStartDate':
    'La date de fin doit avoir lieu le ou après la date de début',
  'work.errors.endTimeAfterStartTime':
    "L'heure de fin doit avoir lieu après l'heure de début",
  'work.errors.repeatMinutesNumber':
    'Les minutes de répétition doivent être un nombre',
  'work.errors.repeatMinutesOutsideBounds':
    'Les minutes de répétition doivent être entre 5 et 1440 minutes',
  'work.errors.repeatMinutesWholeNumber':
    'Les minutes de répétition doivent être un nombre entier',
  'work.errors.scheduleEndIsRequired': "Fin de l'horaire est un champ requis",
  'work.errors.scheduleStartIsRequired':
    "Début de l'horaire est un champ requis",
  'work.errors.startAtTimeIsRequired': 'Heure de début est un champ requis',
  'work.errors.weekdayOptionSelected':
    'Au moins une option de jour de semaine doit être sélectionnée',
  'work.header.newSchedule': 'Nouvel horaire',
  'work.header.scheduledWork': "Travail à l'horaire",
  'work.warning.notRepeating28th':
    '“Les horaires établis pour le 29 ne seront pas répétés pour les mois se terminant le 28',
  'work.warning.notRepeating29th':
    'Les horaires établis pour le 30 ne seront pas répétés pour les mois se terminant le ou avant le 28',
  'work.warning.notRepeating30th':
    'Les horaires établis pour le 31 ne seront pas répétés pour les mois se terminant le ou avant le 28',
  'work.warning.repeatOnShortMonth':
    'Les répétitions à la fin des mois courts tomberont le dernier jour (par ex., 28 fév., 30 avril)',
}

export default frCA
